.App {
  text-align: center;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none;
  border: 1px solid lightgrey !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  overflow-x: hidden !important ;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  color: black !important;
  font-size: 15px !important;
  font-weight: bold !important;
  margin: 10px !important;
}

.navbar-nav .nav-item.active .nav-link {
  /* background-color:#021D5F !important ; */
  color: #ff7000 !important;
  border-bottom: 2px solid #ff7000;
  /* border-radius: 20px !important; */
  font-size: 15px !important;
}
.h_button {
  border: none;
  background-color: #021d5f;
  color: white;
  font-size: 14px;
  padding: 6px;
  border-radius: 5px;
}
.h_button1 {
  border: none;
  background-color: #021d5f;
  color: white;
  font-size: 14px;
  padding: 8px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}
.heading {
  color: black;
  font-weight: 800;
  font-size: 26px;
  text-align: center;
}
.sub_header {
  color: black;
  font-size: 18px;
}
.img_heading {
  color: black;
  font-size: 19px;
  font-weight: 700;
  text-align: center;
}
.desc1 {
  color: black;
  font-size: 14px;
  text-align: center;
}
.hyperlink {
  color: rgb(69, 191, 225);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}
.col_width {
  border-right: 2px solid gray;
  margin-bottom: 70px;
}
.sub_row {
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  padding: 25px;
  margin-top: -60px !important;
}
.desc_heading {
  text-align: left;
  color: grey;
  font-weight: bold;
  font-size: 15px;
}
.heading1 {
  color: black;
  font-weight: 700;
  font-size: 18px;
}
.desc {
  color: black;
  font-size: 14px;
}
.card_row {
  background-color: #f8fafb !important;
  padding: 10px;
}
.price_heading {
  color: black;
  font-size: 22px;
  font-weight: bold;
}
.p_desc {
  color: black;
  font-size: 18px;
  text-align: center;
}
.p_button {
  background-color: #000c60;
  padding: 5px 0px !important;
  border-radius: 20px;
  color: white;
  text-align: center;
  font-size: 14px;
  width: 140px !important;
}
.p_col {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.p_subheading {
  font-size: 16px;
  font-weight: bold;
}
.p_doller {
  font-size: 30px;
  color: #000c60;
  font-weight: bold;
  margin-top: 10px;
}
.p_desc {
  color: #000c60;
  font-size: 18px;
  text-align: left !important;
  margin-top: 10px;
}
.pr_button {
  color: #000c60;
  border: 1px solid grey;
  padding: 6px;
  text-align: center;
  width: 100% !important;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 40px;
}

/* p_1 */
.p_doller1 {
  font-size: 30px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}
.p_desc1 {
  color: white;
  font-size: 15px;
  text-align: left !important;
  margin-top: 10px;
}
.pr1_button {
  color: white;
  border: 1px solid grey;
  padding: 6px;
  text-align: center;
  width: 100% !important;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 40px;
}
.p_subheading1 {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

/* Accordion */
.accordion_text {
  color: #485e75;
  font-size: 18px !important;
  font-weight: bold;
}
.accordion-header {
}

/* Card row */
.c-row {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 20px;
}
.c-heading {
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.c-subheading {
  color: black;
  font-size: 14px;
  margin-top: 10px;
}

/* Tools */

.a-heading {
  font-size: 22px;
  text-align: center;
  color: black;
  font-weight: bold;
}
.a-desc {
  font-size: 18px;
  text-align: center;
  color: black;
  margin-top: 10px;
}
.card-row {
  background-color: #f8fafb;
  padding: 20px;
}
.card_heading {
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.card-desc {
  color: black;
  font-size: 15px;
  margin-top: 10px;
}
.card-img {
  width: 80px !important;
  height: 80px;
  border-radius: 50% !important;
  border: 2px solid grey;
  padding: 5px;
}
.u-text {
  color: black;
  font-size: 16px;
  font-weight: 700;
}
.u-desc {
  color: grey;
  font-size: 15px;
}
.pr_button {
  transition: transform 0.3s ease-in-out;
}
.pr1_button {
  transition: transform 0.3s ease-in-out;
}

.zoomIn {
  transform: scale(1.2);
}
.footer {
  --bg-gradient: linear-gradient(0deg, grey 4.035%, #d0edf1 100%);
  --bg-overlay-color: transparent;

  background: var(--bg-gradient);
  padding: 30px;
  /* position: fixed; 
  bottom: 0; 
  width: 100%; 
  z-index: 100;  */
}
.f-icon {
  font-size: 20px;
  color: white;
  border: 1px solid white;
  padding: 10px 10px;
}
.f-heading {
  color: #f4e48c;
  font-weight: bold;
  font-size: 15px;
}
.f-desc {
  color: #b6aeae;
  font-size: 13px;
  /* font-weight: bold; */
  margin-bottom: 10px;
}
.f-input {
  outline: none;
  padding: 6px 15px;
  border-radius: 10px;
  border: none;
  width: 100%;
  margin-top: 15px;
}
.f-desc1 {
  color: #b6aeae;
  font-size: 13px;
  margin-top: 5px;
}
.b-heading {
  color: white;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.g-color {
  background: linear-gradient(
    to bottom right,
    #f0f2f9,
    #021d5f,
    #d9b1f3,
    #ead4f8,
    #5070f1
  );
  height: 70vh;
}
.blog-heading {
  font-size: 22px;
  color: black;
  font-weight: bold;
}
.blog_row {
  background-color: white;
  padding: 20px;
}
.blog_desc {
  color: grey;
  font-size: 16px;
  margin-top: 10px;
}
.box_shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  90% {
    transform: translateX(-3px);
  }
  20%,
  80% {
    transform: translateX(3px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-3px);
  }
  40%,
  60% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

.box_shadow:hover {
  animation: shake 1s ease;
}
.blog-heading:hover {
  color: #021d5f;
}

.blog-zoom img {
  transition: transform 0.5s ease;
}

.blog-zoom img:hover {
  transform: scale(1.1);
}
.l_label {
  color: black;
  font-size: 14px;
}
.l_input {
  width: 100%;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid grey;
  margin-top: 10px;
}
.l_button {
  background: linear-gradient(to bottom right, #ead4f8, #021d5f, #ead4f8);
  padding: 8px;
  border-radius: 20px;
  color: white;
  text-align: center;
  font-size: 15px;
  margin-top: 25px;
}
.blog_h {
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  letter-spacing: -0.06em;
}
.blog_desc1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.06em;
  color: #60606b;
}
.blog_img {
  width: 100%;
  margin-bottom: 20px;
}
.b_desc {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #021d5f;
  margin: 20px 0;
  display: block;
}
.b_p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #60606b;
}

.read_more {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #fe4a23;
  position: relative;
  margin-top: 10px;
  display: inline-block;
  overflow: hidden;
}

.read_more::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #fe4a23;
  transition: width 0.3s ease;
}

.read_more:hover::before {
  width: 100%;
}
.b_button {
  width: 140px !important;
  border: 2px solid #021d5f;
  text-align: center;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 20px;
}
.b_button:hover {
  width: 140px !important;
  text-align: center !important;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #fe4a23 !important;
  color: white !important;
  border: none !important;
}
.edication {
  color: #021d5f;
  font-size: 15px;
  text-align: center;
  width: 250px !important;
  background-color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
}
.about-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 47px;
  color: #021d5f;
  margin-bottom: 10px;
}
.about_image {
  width: 100% !important;
  height: 462px;
  padding-left: 75px;
}
.about_image1 {
  width: 47%;
  margin-top: -251px;
  margin-left: -0px;
  height: 335px;
  margin-bottom: 25px;
}
.a-button {
  background-color: #021d5f !important;
  width: 150px;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

/* explore */

.col_border {
  border: 1px solid grey;
  border-radius: 5px;
}
.col_icon {
  color: #ffc107;
  font-size: 40px !important;
}
.col-heading {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.col_desc {
  font-size: 14px;
}
.col-center {
  display: flex;
  align-items: center;
}
.bg-image {
  background-image: url("../public//images//Asset-05.png");
  background-repeat: no-repeat;
  background-size: cover;
  top: 16%;
  width: 48%;
  height: 41%;
  position: absolute;
  left: 25px;
}

.courseCard-0-1-330 {
  border: 1px solid #eee;
  cursor: pointer;
  position: relative;
  border-radius: 16px;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.courseCardTag-0-1-331 {
  top: 16px;
  left: 0;
  color: #fff;
  padding: 4px 8px;
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  background-color: #7d8592;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.courseCardImage-0-1-334 {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.courseCardContent-0-1-335 {
  height: 183px;
  padding: 16px;
  word-break: break-all;
}
.courseCardTitle-0-1-336 {
  display: -webkit-box;
  overflow: hidden;
  font-size: 16px;
  margin-top: 0;
  /* max-height: 40px; */
  word-break: break-all;
  font-weight: 700;
  padding-top: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.courseCreatedBy-0-1-338 {
  color: #7d8592;
  display: -webkit-box;
  overflow: hidden;
  margin-top: 8px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.courseCardTags-0-1-333 {
  color: #00ade7;
  display: inline-block;
  padding: 4px 8px;
  font-size: 14px;
  margin-top: 6px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 8px;
  border-radius: 6px;
  background-color: rgba(0, 173, 231, 10%);
}
.courseCardPriceSection-0-1-337 {
  gap: 8px;
  width: 100%;
  bottom: 16px;
  display: flex;
  position: absolute;
  font-size: 18px;
}
.bgcolrforFourthSectLeft {
  font-size: 25px;
  font-weight: 700;
}
.login_heading {
  color: black;
  font-size: 25px;
  text-align: center;
  font-weight: bold !important;
}
.label {
  color: black;
  font-size: 15px;
}
.input_box {
  padding: 8px;
  border-radius: 5px;
  /* margin-top: 10px; */
  width: 100%;
  outline: none;
  border: 1px solid grey;
  padding-left: 15px !important;
}
.hyperlink {
  display: flex;
  justify-content: end;
  text-decoration: none;
  margin-top: 3px;
}
.hyperlink1 {
  display: flex;
  justify-content: end;
  text-decoration: none;
  margin-top: 3px;
  font-size: 15px;
}
.login_button {
  background-color: #021d5f;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 25px;

  cursor: pointer;
}
@keyframes moveUpAnimation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.courseCard-0-1-330 {
  animation: moveUpAnimation 0.5s ease forwards;
  opacity: 0;
}

.courseCard-0-1-330:nth-child(1) {
  animation-delay: 0s;
}

.courseCard-0-1-330:nth-child(2) {
  animation-delay: 0.1s;
}
.home-page-section-one-text {
  color: white;
  font-weight: 600;
  font-size: 40px;
}
.home-style-bg-image {
  height: 240px;
  position: absolute;
  right: 16px;
  bottom: 231px;
}
.mheader-mobile {
  display: none !important;
}
.mobile-tools {
  display: none !important;
}
.mobile_header {
  display: none !important;
}
@media (max-width: 768px) {
  .home-style-bg-image {
    height: 153px;
    position: absolute;
    right: 16px;
    bottom: 164px;
  }
  .mobile_header {
    display: block !important;
  }
  .web-header {
    display: none !important;
  }

  .home-page-section-one-text {
    font-size: 30px;
  }
  .mheader-mobile {
    display: flex !important;
  }
  .mheader-web {
    display: none !important;
  }
  .web-tools {
    display: none !important;
  }
  .mobile-tools {
    display: block !important;
  }
}
.t_head {
  font-size: 13px;
  color: black;
}
.t-img {
  width: 100px;
  height: 100px;
}
.t-hyperlink {
  color: #0099ffdb;
  font-size: 13px;
  margin-left: 15px;
  font-weight: normal;
}
.t_desc {
  font-size: 13px;
  color: grey;
  font-weight: normal;
  margin-left: 5px;
  text-align: center;
}
.s_tar {
  font-size: 10px;
}
.t_desc1 {
  font-size: 13px;
  color: grey;
  font-weight: normal;
  margin-left: 5px;
  text-align: end;
}
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.table-container {
  margin-top: 20px;
}

.table-header {
  background-color: #f0f0f0;
}

.table-row:hover {
  background-color: #f9f9f9;
}

.table-cell {
  padding: 10px;
}

.product-image {
  width: 100px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 1024px) and (min-width: 600px) {
  .f-heading {
    color: #f4e48c;
    font-weight: bold;
    font-size: 15px;
    text-align: left !important;
  }
  .web-tools {
    display: none !important;
  }
  .mobile_header {
    display: block !important;
  }
  .web-header {
    display: none !important;
  }
  .mobile-tools {
    display: block !important;
  }
  .f-desc {
    text-align: left !important;
  }
  .e-icon {
    display: flex !important;
    justify-content: center;
  }
  .e-heading {
    text-align: center !important;
  }
  .e-desc {
    text-align: center !important;
  }
  .e-space {
    gap: 10px !important;
  }
  .mheader-mobile {
    display: flex !important;
  }
  .mheader-web {
    display: none !important;
  }
}

/* Styles for mobile devices (screens with a width less than 600px) */
@media (max-width: 599px) {
  .f-heading {
    color: #f4e48c;
    font-weight: bold;
    font-size: 15px;
    text-align: left !important;
  }
  .mobile_header {
    display: block !important;
  }
  .web-header {
    display: none !important;
  }
  .f-desc {
    text-align: left !important;
  }
  .web-tools {
    display: none !important;
  }
  .mobile-tools {
    display: block !important;
  }

  .e-heading {
    text-align: center !important;
  }
  .e-desc {
    text-align: center !important;
  }
  .e-space {
    gap: 10px !important;
  }
  .mheader-mobile {
    display: flex !important;
  }
  .mheader-web {
    display: none !important;
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages {
  flex: 1;
  overflow-y: auto;
}

.message {
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
  max-width: 60%;
}

.sent {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.received {
  background-color: #ffffff;
  align-self: flex-start;
}

.timestamp {
  font-size: 0.8em;
  color: gray;
  margin-top: 5px;
}

.input-container111 {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

textarea {
  /* flex: 1; */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  /* resize: none; */
}

button {
  margin-left: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.chat-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  max-width: 900px;
  margin: 0 auto;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.chat-header {
  padding: 10px;
  background-color: #075e54;
  color: white;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-title {
  font-size: 20px;
  font-weight: bold;
}

.chat-list {
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.chat-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.chat-item.selected {
  background-color: #dcf8c6;
}

.chat-users {
  font-weight: bold;
}

.chat-latest-message {
  font-size: 14px;
  color: #555;
}

.chat-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #e5ddd5;
}

.no-chat-selected {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
}

.messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: "79vh";
}

.message {
  margin-bottom: 10px;
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: "79vh";
}

.message.sent {
  align-self: flex-end;
  background-color: #dcf8c6;
}

.message.received {
  align-self: flex-start;
  background-color: #8080804a !important;
}

.message-content {
  padding: 0px !important;
  border-radius: 10px;
  max-width: 400px; /* Adjust this value to control line length */
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 16px;
}

.message-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 5px;
  color: #555;
}

.chat-input {
  display: flex;
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.input-field {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
}

.send-button {
  background-color: #075e54;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #064e47;
}
.b-button {
  background-color: blue;
  text-align: center;
  border-radius: 20px;
  padding: 8px;
  width: 40%;
  color: white;
  transition: all 0.2s ease-in;
  cursor: pointer;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.b-button:hover {
  border: 3px solid blue;
  color: white;
  animation: pulse 0.4s ease-in-out infinite;
  cursor: pointer;
}

/* Custom Modal Styles */
.custom-modal .modal-content {
  background: linear-gradient(135deg, #2c2c54, #40407a);
  /* border-radius: 20px; */
  color: white;
  text-align: center;
  padding: 20px;
  /* box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4); */
}

.custom-modal-header {
  border-bottom: none;
  justify-content: center;
  background: linear-gradient(90deg, #ff9a9e, #fad0c4);
}

.custom-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.custom-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.modal-content-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.plan {
  background: #fff;
  color: #333;
  width: 300px;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.plan:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

.plan-icon img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f9c74f;
  padding: 10px;
}

.plan-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-top: 10px;
  color: #2d3436;
}

.plan-description {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #636e72;
  margin: 10px 0;
}

.plan-price {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #0984e3;
  margin-bottom: 15px;
}

.custom-proceed-button {
  background: linear-gradient(135deg, #6c5ce7, #74b9ff);
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.custom-proceed-button:hover {
  background: linear-gradient(135deg, #74b9ff, #6c5ce7);
}

.custom-cancel-button {
  background: #d63031;
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.custom-cancel-button:hover {
  background: #e84393;
}
.custom-select-container {
  position: relative;
  display: inline-block;
  width: 200px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  appearance: none; /* Remove default arrow */
  background-color: #fff;
}

.flag-container {
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none; /* Make the flag non-clickable */
  width: "100%" !important;
}

.flag-icon {
  width: 20px;
  height: 15px;
  border-radius: 3px;
}
.dropdown-toggle::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* Keyword Suggestions Section */
.keyword-suggestions {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
  width: fit-content;
  margin: 0 auto;
}

.keyword-table {
  width: 100%;
  border-collapse: collapse;
}

.keyword-table th,
.keyword-table td {
  border: 1px solid #ddd;
  padding: 10px 15px;
  text-align: left;
}

.keyword-table th {
  background-color: #007bff;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
}

.keyword-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.keyword-table tr:hover {
  background-color: #e9f5ff;
}

.keyword-table td {
  font-size: 14px;
  color: #333;
}

.keyword-table tr:hover td {
  color: #007bff;
}
button:hover {
  background-color: transparent !important;
}

.pricingSection {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  background: linear-gradient(135deg, #1f1f47, #101028);
  min-height: 100vh;
  align-items: center;
}

.card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  width: 280px;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.icon {
  font-size: 40px;
  color: #ffd700;
  margin-bottom: 10px;
}

.title {
  font-size: 22px;
  margin: 10px 0;
}

.price {
  font-size: 20px;
  color: black;
  margin: 10px 0;
}

.searches {
  font-size: 16px;
  margin: 10px 0;
}

.save {
  font-size: 14px;
  color: #00ff99;
  font-weight: bold;
}

.button {
  margin-top: 15px;
  background: linear-gradient(45deg, #ffd700, #ffa500);
  color: #101028;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background: linear-gradient(45deg, #ffa500, #ffd700);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .pricingSection {
    flex-direction: column;
    align-items: center;
  }
}
h3.poppins-medium {
  font-weight: 500;
  color: #343a40;
}

.form-label {
  font-weight: 500;
  color: #495057;
  font-family: "poppins";
  font-size: 14px !important;
}

input.form-control,
select.form-select {
  border-radius: 8px;
  border: 1px solid #ced4da;
  font-family: "poppins";
  font-size: 14px;
}

input.form-control:focus,
select.form-select:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  font-weight: bold;
  border-radius: 8px;
} */

.dropdown-menu {
  margin-left: -30px !important;
}

/* Paymentsuccess Page */

/* Main container styling */
.payment-success-container {
  font-family: "Arial, sans-serif";
  text-align: center;
  padding: 40px;
  background: linear-gradient(to bottom, #f8f9fa, #e9ecef);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Hero Section */
.hero-section {
  margin-bottom: 20px;
}

.checkmark-icon {
  font-size: 60px;
  color: #28a745;
  margin-bottom: 10px;
  animation: pop-in 0.5s ease-in-out;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.hero-section h1 {
  font-size: 32px;
  color: #333;
  margin: 10px 0;
}

.hero-section p {
  font-size: 18px;
  color: #555;
}

/* Help Section */
.help-section {
  margin-top: 20px;
  font-size: 14px;
}

.help-section a {
  color: #007bff;
  text-decoration: none;
}

.help-section a:hover {
  text-decoration: underline;
}

/* user data */
.filled-star {
  color: gold;
}

.empty-star {
  color: lightgray;
}
.bell {
  font-size: 8px;
  background-color: red;
  color: white;
  padding: 3px 7px;
  border-radius: 50px;
  position: absolute;
  top: 17px;
  right: 19px;
}

/* chat */

.chat-container {
  display: flex;
  height: 100vh;
  background: linear-gradient(135deg, #e9eff5, #f0f2f5);
  font-family: "Roboto", sans-serif;
}

.auth-form {
  margin: auto;
  text-align: center;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.auth-form h2 {
  font-size: 24px;
  color: #333;
}

.auth-form input {
  display: block;
  margin: 15px auto;
  padding: 12px;
  width: 90%;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
}

.auth-form button {
  padding: 12px 25px;
  background: linear-gradient(45deg, #00a884, #005f59);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease;
}

.auth-form button:hover {
  background: linear-gradient(45deg, #005f59, #00a884);
}

.auth-form .auth-link {
  color: #00a884;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.chat {
  display: flex;
  width: 100%;
}

.chat-sidebar {
  width: 25%;
  background: #f8f9fa;
  padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}

.chat-sidebar h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.chat-sidebar input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #edf1f7;
}

.chat-main h3 {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  background: #ffffff;
  font-size: 18px;
  color: #333;
}

.chat-messages {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 15px;
  max-width: 75%;
  font-size: 14px;
  line-height: 1.4;
  word-wrap: break-word;
}

.message.sent {
  background: #dcf8c6;
  align-self: flex-end;
}

.message.received {
  background: white;
  align-self: flex-start;
  border: 1px solid #ddd;
}

.chat-input {
  display: flex;
  padding: 15px;
  background: #ffffff;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  outline: none;
  font-size: 14px;
}

.chat-input button {
  padding: 12px 20px;
  margin-left: 10px;
  background: linear-gradient(45deg, #00a884, #005f59);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease;
}

.chat-input button:hover {
  background: linear-gradient(45deg, #005f59, #00a884);
}

.user-list {
  margin-top: 20px;
}

.user {
  padding: 12px;
  cursor: pointer;
  background: white;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  transition: transform 0.3s ease, background 0.3s ease;
}

.user:hover {
  background: #e9eff5;
  transform: scale(1.02);
}

.active-user {
  background: linear-gradient(45deg, #00a884, #005f59);
  color: white;
}

.notification-badge {
  background: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
}

.group-controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.group-controls button {
  padding: 8px 15px;
  background: linear-gradient(45deg, #00a884, #005f59);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.group-controls button:hover {
  background: linear-gradient(45deg, #005f59, #00a884);
  transform: translateY(-2px);
}

.group-controls button:active {
  transform: translateY(0);
}

.group {
  margin-bottom: 15px;
}

.group .user {
  display: inline-block;
  width: calc(100% - 100px);
}

.group-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.group-controls {
  display: flex;
  gap: 10px;
}

.group-controls button {
  padding: 8px 15px;
  background: linear-gradient(45deg, #00a884, #005f59);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.group-controls button:hover {
  background: linear-gradient(45deg, #005f59, #00a884);
  transform: translateY(-2px);
}

.group-controls button:active {
  transform: translateY(0);
}
.chat-button-container {
  position: fixed;
  right: 20px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #42a5f5, #478ed1);
  border-radius: 50%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, box-shadow 0.3s;
  z-index: 1000;
  cursor: pointer;
}

.chat-button-container:hover {
  transform: scale(1.1);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.chat-icon {
  font-size: 24px;
  color: white;
}
.pricing-card {
  background-color: #2c2c2c;
  border-radius: 15px;
  padding: 20px;
  color: #ffffff;
}

.icon {
  margin-bottom: 10px;
}

.price {
  font-size: 2rem;
  font-weight: bold;
}

.card {
  border: none;
}

.shadow-lg {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.8);
}

.accordion {
  max-width: 600px;
  margin: auto;
}

.accordion-item {
  background: #444;
  margin: 10px 0;
  border-radius: 5px;
  overflow: hidden;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  font-size: 18px;
  font-weight: bold;
  background: #555;
  cursor: pointer;
  color: #ffb15f;
  border: none;
}

.accordion-header:hover {
  background: #666;
}

.accordion-header.active {
  background: #666;
}

.accordion-body {
  display: none;
  padding: 6px 10px;
  font-size: 16px;
  color: #ddd;
  background: #333;
}

.accordion-body.show {
  display: block;
}

.accordion-header.active .icon {
  transform: rotate(180deg);
}
.plan-details p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

/* Coupon Input & Apply Button */
.apply-coupon {
  margin-top: 5px;
  gap: 10px;
}

.coupon-input {
  flex-grow: 1;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

/* Buttons */
.apply-btn {
  height: 45px;
  font-size: 14px;
  padding: 0 15px;
}

.buy-now-btn {
  background-color: #007bff;
  border: none;
  font-size: 16px;
  padding: 10px 20px;
}
.buy-now-btn:hover {
  background-color: #0056b3;
}
